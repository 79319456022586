import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import CarsayoCharacterPopup from "../../components/CarsayoCharacterPopup";
import Expression from "../../components/svg/Expression";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character03 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section03">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <img
              src="../imgs/svg/character-section03-img.svg"
              alt="카집사 이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>
                  고객님 무엇이 궁금하신지요?
                  <br />
                  공감력 100%의 상담 전문가
                </p>
                <h5>카집사</h5>
              </div>
            </div>
          </div>
          <div className="right">
            <VerticalMoveAnimation
              element="div"
              className="right__title"
              duration={0.5}
              direction="top"
            >
              <p>#2 HEROES 2025</p>
              <h3>
                <span>카집사</span>
              </h3>
            </VerticalMoveAnimation>
            <div className="right__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  고객님의 표정과 음성만 듣고도 무엇을 원하는지 캐치하는 눈치
                  빠른 남자!
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>고객의 질문에 친절히 답하고 그들이 힘들어할 때</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.35}
                duration={0.5}
                direction="left"
              >
                <span>곁에서 공감해 주는 카사요의 진정한 고객 지킴이!</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.4}
                duration={0.5}
                direction="left"
              >
                <span>고객을 최우선으로 생각하는 마인드만큼은 세계 최고!</span>
              </HorizontalMoveAnimation>
            </div>
            <Expression
              textColor={"#fff"}
              fill="#FF5B79"
              clickEvent={setPopupOpen}
            />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip03.png"
                alt="캐릭터 클립 이미지3"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character03;
