import React, { useEffect, useState } from "react";
import CarsayoMobileHeader from "./mobileHeader";
import CarsayoMobileFooter from "./mobileFooter";
import MCarsayoGiftPopup from "../components/MCarsayoGiftPopup";

interface CarsayoContentWrapInterface {
  children: React.ReactNode;
  hiddenHeader?: boolean;
  hiddenFooter?: boolean;
}

const CarsayoMobileWrap = ({
  children,
  hiddenHeader,
  hiddenFooter,
}: CarsayoContentWrapInterface) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const popupLocalStorage = localStorage.getItem("mPopupTodayHidden");

    if (!popupLocalStorage) {
      setIsPopupOpen(true);
      return;
    }

    if (Date.now() > Number(popupLocalStorage)) {
      localStorage.removeItem("mPopupTodayHidden");
      setIsPopupOpen(true);
    } else {
      setIsPopupOpen(false);
    }
  }, []);
  return (
    <div className="m-main">
      {hiddenHeader === true ? null : <CarsayoMobileHeader />}

      {children}
      {hiddenFooter === true ? null : <CarsayoMobileFooter />}
      <MCarsayoGiftPopup isOpen={isPopupOpen} setIsOpen={setIsPopupOpen} />
    </div>
  );
};
export default CarsayoMobileWrap;
