import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character02 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  return (
    <div className="section character__section02">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <VerticalMoveAnimation
              element="div"
              className="left__title"
              duration={0.5}
              direction="top"
            >
              <p>#1 HEROES 2025</p>
              <h3>
                <span>카키</span>
              </h3>
            </VerticalMoveAnimation>
            <div className="left__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>
                  차를 사는 사람의 마음을 가장 잘 이해하는 딜러이자 경영자!
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>
                  고객이 원하는 최적의 조건을 신속하고 정확하게 제시하며{" "}
                </span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>소비자중심경영, 품질경영, 환경경영 등 고객에게</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>신뢰를 안겨주기 위한 철저한 준비로 늘 분주하다.</span>
              </HorizontalMoveAnimation>
            </div>
            <Expression textColor="#fff" clickEvent={setPopupOpen} />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip02.png"
                alt="캐릭터 클립 이미지2"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
          <div className="right">
            <img
              src="../imgs/svg/character-section02-img.svg"
              alt="카키 이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>
                  26년 금융 무사고!
                  <br />
                  대한민국 최고 세일즈 기록 보유자
                </p>
                <h5>카키</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character02;
