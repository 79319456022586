import { useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../utils/verticalMoveAnimation";
import React from "react";
import { useNavigate } from "react-router-dom";

interface MainInterface {
  currentOS: string;
}

const MainSection05 = ({ currentOS }: MainInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="section wrap">
      <div className="main-section05" style={{ overflowY: "hidden" }}>
        <div className="main-section05__top-wrap">
          <div className="main-section05__top-wrap__title">
            <h3>
              <VerticalMoveAnimation
                element="div"
                duration={0.5}
                direction="top"
                setAnimated={setAnimated}
              >
                <div className="strong-title">
                  <div>Super Car</div>
                  <span className={isAnimated ? "animation" : ""}>
                    <UnderlineSketch fill="#FFE492" width="257" height="52" />
                  </span>
                </div>
              </VerticalMoveAnimation>
              <VerticalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="top"
                className="normal-title"
              >
                <span>With</span>
                <span>CARSAYO</span>
              </VerticalMoveAnimation>
            </h3>
          </div>
          <button
            onClick={() => {
              currentOS === "iOS" || currentOS === "Mac"
                ? window.open(
                    `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                  )
                : window.open(
                    `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
                  );
            }}
          >
            슈퍼카 구입 프로세스
            <img src="../imgs/svg/smallArrow_right.svg" alt="작은우측화살표" />
          </button>
        </div>
        <div className="main-section05__content">
          <ul>
            <li>
              <div>
                <span>01</span>
              </div>
              <img src="../imgs/png/superCar01.png" alt="" />
              <h4>롤스로이스</h4>
            </li>
            <li>
              <div>
                <span>02</span>
              </div>
              <img src="../imgs/png/superCar02.png" alt="" />
              <h4>람보르기니</h4>
            </li>
            <li>
              <div>
                <span>03</span>
              </div>
              <img src="../imgs/png/superCar03.png" alt="" />
              <h4>벤틀리</h4>
            </li>
            <li>
              <div>
                <span>04</span>
              </div>
              <img src="../imgs/png/superCar04.png" alt="" />
              <h4>페라리</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MainSection05;
