import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import React from "react";
import UnderlineSketch from "../components/svg/UnderlineSketch";

const CarsayoMobileHeader = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isActive]);

  return (
    <header
      style={{
        width: "100%",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "30",
      }}
    >
      <div className="m-header">
        <div className="m-header-content">
          <button
            className="m-header-content__logo"
            onClick={() => navigate("/")}
          >
            <h1>
              <img src="../imgs/svg/casayo_full.svg" alt="카사요 로고" />
            </h1>
          </button>
          <button
            className={`m-header-content__menuBtn ${isActive ? "active" : ""}`}
            onClick={() => setIsActive(!isActive)}
          >
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </button>
        </div>
        <AnimatePresence>
          {isActive && (
            <motion.div
              className="m-dropdown"
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -30 }}
              transition={{ duration: 0.3 }}
            >
              <div className="m-dropdown-menu">
                <div
                  onClick={() => navigate("/promotion/service")}
                  style={{ cursor: "pointer" }}
                >
                  <p style={{ cursor: "pointer" }}>프리미엄 혜택</p>
                  <img
                    src="../imgs/svg/shopping.svg"
                    alt="프리미엄혜택 쇼핑아이콘"
                  />
                </div>
                <div>
                  <p>회사소개</p>
                  <ul>
                    <li onClick={() => navigate("/company/introduction")}>
                      <h2>CEO 메시지</h2>
                      <img src="../imgs/svg/menu-sms-tracking.svg" alt="" />
                    </li>
                    <li onClick={() => navigate("/company/overview")}>
                      <h2>기업 개요</h2>
                      <img src="../imgs/svg/menu-chart-success.svg" alt="" />
                    </li>
                    <li onClick={() => navigate("/company/philosophy")}>
                      <h2>경영 이념</h2>
                      <img src="../imgs/svg/menu-book.svg" alt="" />
                    </li>
                    <li onClick={() => navigate("/company/logo")}>
                      <h2>CI / BI</h2>
                      <img
                        src="../imgs/svg/menu-notification-status.svg"
                        alt=""
                      />
                    </li>
                    <li onClick={() => navigate("/company/character")}>
                      <h2>캐릭터 스토리</h2>
                      <img src="../imgs/svg/bubble.svg" alt="버블아이콘" />
                    </li>
                  </ul>
                  <div
                    className="character-menu"
                    style={{
                      backgroundImage: 'url("../imgs/svg/premium-bg.svg")',
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/company/character")}
                  >
                    <img
                      src="../imgs/svg/m-menu-character.svg"
                      alt="카사요히어로즈메뉴이미지"
                    />
                    <div>
                      <img
                        src="../imgs/png/m-menu-logo.png"
                        alt="작은심볼로고"
                      />
                      <div>
                        <h3>
                          <span style={{ fontFamily: "Times New Roman" }}>
                            Car
                          </span>
                          <span style={{ fontFamily: "Times New Roman" }}>
                            Heroes Rise
                          </span>
                        </h3>
                        <span>
                          <UnderlineSketch
                            fill="#FFE492"
                            width="80"
                            height="12"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <div onClick={() => navigate("/esg/management")}>
                      <h2>ESG 경영</h2>
                      <p>
                        <span>환경, 사회, 건전한 기업 지배구조를 위한</span>
                        <span>
                          카사요(주)의 정책 / 가이드 라인을 소개합니다.
                        </span>
                      </p>
                    </div>
                    <div>
                      <img src="../imgs/png/subMenuImg1.png" alt="" />
                      <span>ESG Approach</span>
                    </div>
                  </div>
                  <div>
                    <div onClick={() => navigate("/cc/management")}>
                      <h2>
                        소비자 중심 경영(CCM)
                        <span>
                          <img
                            src="../imgs/png/ccm_mark.png"
                            alt="ccm인증마크"
                          />
                        </span>
                      </h2>
                      <p>
                        <span>
                          카사요(주)는 소비자 중심 경영(CCM)을 지향하며, 고객의
                          필요와
                        </span>
                        <span>
                          요구를 최우선으로 고려하는 경영 방식을 채택하고
                          있습니다.
                        </span>
                      </p>
                    </div>
                    <div>
                      <img src="../imgs/png/subMenuImg2.png" alt="" />
                      <span>CCM Approach</span>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};
export default CarsayoMobileHeader;
