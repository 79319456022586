import { motion } from "framer-motion";
import { useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import React from "react";

const Introduction03 = () => {
  const [isActive, setActive] = useState("career");

  const handleClickEvents = {
    menu: (menu: string) => () => {
      setActive(menu);
    },
  };

  return (
    <div className="section introduction__section03">
      <div className="company__section__title">
        <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
          CEOPROFILE
          <span>CEO 프로필</span>
        </VerticalMoveAnimation>
      </div>
      <div className="introduction__section03__content">
        <ul className="introduction__section03__content__menu">
          <li
            className={`${isActive === "career" && "active"}`}
            onClick={handleClickEvents.menu("career")}
          >
            주요 경력
          </li>
          <li
            className={`${isActive === "record" && "active"}`}
            onClick={handleClickEvents.menu("record")}
          >
            주요 기록
          </li>
          <li
            className={`${isActive === "winning" && "active"}`}
            onClick={handleClickEvents.menu("winning")}
          >
            수상 기록
          </li>
          <li
            className={`${isActive === "news" && "active"}`}
            onClick={handleClickEvents.menu("news")}
          >
            보도 자료
          </li>
        </ul>
        <div className="introduction__section03__content__box">
          {/* 주요 경력 */}
          <div
            className={`${isActive === "career" && "active"}`}
            style={{ background: "url('../imgs/png/carrier-bg.png')" }}
          >
            <div>
              <h4>
                <span>KEY POINT</span>
                CAREER
              </h4>
              <p>
                <span>국내·수입 자동차 판매 통합 시스템 총괄 컨설턴트</span>
                <span>(신차, 중고차, 수입·수출, 금융, 보험, A/S)</span>
              </p>
            </div>
            <ul>
              <li>
                수입차 브랜드 대상 세일즈 / 마케팅 / 직원 서비스 컨설팅 및 교육
              </li>
              <li>
                구 LG카드(현 신한카드) 리스 총괄 컨설팅 등 자동차 금융 설계 최고
                경력
              </li>
              <li>
                서비스 TOP 브랜드(LEXUS) 4년 연속 고객 관리 최고 평점 기록
              </li>
            </ul>
          </div>
          {/* 주요 기록 */}
          <div
            className={`${isActive === "record" && "active"}`}
            style={{ background: "url('../imgs/png/record-bg.png')" }}
          >
            <div>
              <h4>
                <span>KEY POINT</span>
                RECORD
              </h4>
              <p>
                <span>
                  저서 “세일즈 신화를 만드는 힘” - 한국 경제 신문 출판
                </span>
                <span>
                  세계 3대 인명 사전 &#39; Marquis Who's Who&#39;에
                  &#39;marketing professional consultant&#39;로 등재
                </span>
                <span>
                  세계 3대 인명 사전 &#39;IBC, International Biographical
                  Centre&#39;에 &#39;Leading Professionals of the World&#39;로
                  등재
                </span>
              </p>
            </div>
            <ul>
              <li>IBK 중소기업 CEO Report 칼럼 기고</li>
              <li>
                [2013 한국 현대인물열전 33선] 한국인물연구원
                (한국현대인물열전편찬회)
              </li>
              <li>
                대한민국 세일즈 최고 기록(한국 기록원) 각 세일즈 분야 대상 수상
                기록 최다 보유자
              </li>
            </ul>
          </div>
          {/* 수상 기록 */}
          <div
            className={`${isActive === "winning" && "active"}`}
            style={{ background: "url('../imgs/png/winning-bg.png')" }}
          >
            <div>
              <h4>
                <span>KEY POINT</span>
                WINNING
              </h4>
              <p>
                <span>2004년 ~ 2006년 LEXUS 판매왕</span>
                <span>2010년 INSURANCE MDRT</span>
              </p>
            </div>
            <ul>
              <li>2003년 GM(Cadilac, Saab) 판매왕</li>
              <li>2002년 BMW 판매왕</li>
              <li>2000년 ~ 2001년 DAEWOO 판매왕</li>
            </ul>
          </div>
          {/* 보도 자료 */}
          <div
            className={`${isActive === "news" && "active"}`}
            style={{ background: "url('../imgs/png/news-bg.png')" }}
          >
            <div>
              <h4>
                <span>KEY POINT</span>
                NEWS & MEDIA
              </h4>
              <p>
                <span>YTN 방송 방영 : 판매왕의 비결 “사람이 재산"</span>
                <span>SBS 방송 방영 : 수입차 판매왕이 말하다</span>
                <span>
                  한국경제 지면 & 온라인 보도 : 실적보다 중요한 건 고객과의 인연
                </span>
                <span>
                  매일경제 지면 & 온라인 보도 : 3일에 1대 판매, 수입차
                  판매왕들의 비법은?
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Introduction03;
