import { motion } from "framer-motion";
import { useState } from "react";
import UnderlineSketch from "../components/svg/UnderlineSketch";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import SwiperClass from "swiper/types/swiper-class";
import Star from "../components/svg/Star";
import "swiper/swiper-bundle.css";
import React from "react";
import CarsayoFooter from "../layout/footer";
import { ChevronLeft, ChevronRight } from "lucide-react";

const MainSection10 = () => {
  const [isAnimated, setAnimated] = useState(false);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);

  const slideContents = [
    {
      profile: "../imgs/png/profile/profile01.png",
      rating: 5,
      title: "모든 과정이 빠르고 간단합니다.",
      type: "내 차 팔기",
      nickName: "정민서",
      reviewText: `무엇보다도 가격을 최고가로 받을 수 있는 게 좋은 앱이네요.
      훌륭합니다. 입찰제 중고차 판매가 마음에 듭니다.`,
    },
    {
      profile: "../imgs/png/profile/profile02.png",
      rating: 5,
      title: "편리하게 구매했어요.",
      type: "신차 구매",
      nickName: "태익김",
      reviewText: `새 차 구입할 때 많은 혜택도 받았어요. 추천합니다.
편리하게 차를 사고파는 게 가능해서 너무 좋아요.`,
    },
    {
      profile: "../imgs/png/profile/profile03.png",
      rating: 5,
      title: "많은 혜택을 받았어요.",
      type: "신차 구매",
      nickName: "람아",
      reviewText: `앱 뛰어납니다. 정말 좋네요. 신차 구입을 했어요.
많은 혜택으로 정말 만족스러운 구매를 했네요.`,
    },
    {
      profile: "../imgs/png/profile/profile04.png",
      rating: 5,
      title: "인생 첫차를 카사요에서 구입했어요.",
      type: "신차 구매",
      nickName: "브라보마이라이프",
      reviewText: `염려되는 부분에 대한 설명 및 계약 후 인도까지 일사천리로 진행되었네요. 진심과 최선을 다해주신 카 매니저님 감동입니다.`,
    },
    {
      profile: "../imgs/png/profile/profile05.png",
      rating: 5,
      title: "지인에게 추천하고 싶은 앱이에요.",
      type: "내 차 팔기",
      nickName: "로또",
      reviewText: `새 차를 알아보다가 추천으로 앱을 깔고 보니 내 차도 팔게
      되어있어 다 해봤습니다. 둘 다 되니 좋습니다. 감사합니다.`,
    },
    {
      profile: "../imgs/png/profile/profile06.png",
      rating: 5,
      title: "최고가로 판매할 수 있어요.",
      type: "내 차 팔기",
      nickName: "머리카락카리나",
      reviewText: `이용법도 참 쉽고 간편하고 견적 받으면 딜러분들이 입찰해서
      최고가를 맞출 수 있었어요.`,
    },
  ];

  return (
    <div className="section">
      <div className="main-section10">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.5 },
          }}
          onAnimationStart={() => setAnimated(false)}
          onAnimationComplete={() => setAnimated(true)}
        >
          <h3>많은 분들이 좋은 조건으로 구매했어요</h3>
          <span className={isAnimated ? "animation" : ""}>
            <UnderlineSketch fill="#FFE492" />
          </span>
        </motion.div>

        <div className="main-section10__swiper">
          <Swiper
            slidesPerView={3}
            onRealIndexChange={(swiper: SwiperClass) =>
              setPage(Math.ceil(swiper.activeIndex + 1))
            }
            onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
            centeredSlides={true}
          >
            {slideContents.map((review) => (
              <SwiperSlide>
                <div className="reviewSlide">
                  <div className="reviewSlide-box">
                    <div className="profile-box">
                      <img src={review.profile} alt="프로필 이미지" />
                    </div>
                    <div className="reviewSlide-content">
                      <div className="rating-box">
                        {Array.from(
                          { length: review.rating ?? 0 },
                          (_, index) => (
                            <Star
                              key={`score_star_${index}`}
                              width={"30"}
                              height={"30"}
                              fill={"#FFC93E"}
                            />
                          )
                        )}
                      </div>
                      <div className="review-top">
                        <strong>{review.title}</strong>
                        <span>
                          [{review.type}] {review.nickName} 님
                        </span>
                      </div>
                      <p>{review.reviewText}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="pagination">
            <div
              style={{
                cursor: page === 1 ? "default" : "pointer",
              }}
              onClick={() => swiper?.slidePrev()}
            >
              <ChevronLeft
                width={28}
                height={28}
                strokeWidth={3}
                stroke={page === 1 ? "#D5DCEB" : "#1A1F27"}
              />
            </div>
            <ul>
              {slideContents.map((_, index) => (
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    swiper?.slideTo(index);
                  }}
                  className={index + 1 === page ? "active" : "default"}
                ></li>
              ))}
            </ul>
            <div
              style={{
                cursor: page === slideContents.length ? "default" : "pointer",
              }}
              onClick={() => swiper?.slideNext()}
            >
              <ChevronRight
                width={28}
                height={28}
                strokeWidth={3}
                stroke={page === slideContents.length ? "#D5DCEB" : "#1A1F27"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainSection10;
