import { useEffect, useState } from "react";
import CarsayoButton from "../components/CarsayoButton";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import OSCheck from "../components/OSCheck";

interface HeaderInterface {
  currentPage: number;
}

const CarsayoHeaderV2 = ({ currentPage }: HeaderInterface) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isHovered, setIsHovered] = useState<number | null>(null);

  const currentOS = OSCheck();

  const handleMouseEnter = (index: number) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  useEffect(() => {
    if (currentPage !== 1) {
      setIsHovered(null);
    }
  }, [currentPage]);

  return (
    <div
      className="header-content"
      style={{
        position: "fixed",
        top: 0,
        flex: "none",
        zIndex: 100,
        display: currentPage === 0 ? "" : "none",
      }}
    >
      <div className="wrap">
        <div className="left">
          <button onClick={() => navigate("/")}>
            <h1>
              <img src="../imgs/png/casayo_full.png" alt="카사요 로고" />
            </h1>
          </button>
          <ul>
            <li
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
              onClick={() => navigate("/promotion/service")}
              style={{
                fontWeight: location.pathname.includes("promotion")
                  ? "700"
                  : "500",
              }}
            >
              프리미엄 혜택
            </li>
            <li
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
              onClick={() => navigate("/company/introduction")}
              style={{
                fontWeight: location.pathname.includes("company")
                  ? "700"
                  : "500",
              }}
            >
              회사 소개
            </li>
            <li
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
              onClick={() => navigate("/esg/management")}
              style={{
                fontWeight: location.pathname.includes("esg") ? "700" : "500",
              }}
            >
              ESG 경영
            </li>
            <li
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
              onClick={() => navigate("/cc/management")}
              style={{
                fontWeight: location.pathname.includes("cc") ? "700" : "500",
              }}
            >
              <span>
                <img src="../imgs/png/ccm_mark.png" alt="ccm 인증마크" />
              </span>
              소비자 중심 경영
            </li>
          </ul>
        </div>
        <div className="right">
          <CarsayoButton onIcon="../imgs/svg/download.svg">
            <a
              href={
                currentOS === "iOS" || currentOS === "Mac"
                  ? `https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`
                  : `https://play.google.com/store/apps/details?id=com.ksapp.carsayo`
              }
              target="_blank"
              rel="noreferrer"
            >
              앱 다운로드
            </a>
          </CarsayoButton>
        </div>
      </div>
      <AnimatePresence>
        {isHovered && isHovered > 0 && (
          <motion.div
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
            className="dropdown"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            <div className="dropdown-menu">
              <div
                className="character-menu"
                onClick={() => navigate("/promotion/service")}
                // onClick={() => navigate("/company/character")}
              >
                <img src="../imgs/svg/menu-character.svg" alt="" />
                <span>Car Heroes Rise</span>
              </div>
              <ul>
                <li onClick={() => navigate("/company/introduction")}>
                  <h2
                    className={`${
                      location.pathname === "/company/introduction" && "active"
                    }`}
                  >
                    CEO 메시지
                  </h2>
                </li>
                <li onClick={() => navigate("/company/overview")}>
                  <h2
                    className={`${
                      location.pathname === "/company/overview" && "active"
                    }`}
                  >
                    기업 개요
                  </h2>
                </li>
                <li onClick={() => navigate("/company/philosophy")}>
                  <h2
                    className={`${
                      location.pathname === "/company/philosophy" && "active"
                    }`}
                  >
                    경영 이념
                  </h2>
                </li>
                <li onClick={() => navigate("/company/logo")}>
                  <h2
                    className={`${
                      location.pathname === "/company/logo" && "active"
                    }`}
                  >
                    CI / BI
                  </h2>
                </li>
                <li onClick={() => navigate("/company/character")}>
                  <h2
                    className={`${
                      location.pathname === "/company/character" && "active"
                    }`}
                  >
                    Character Story
                  </h2>
                </li>
              </ul>
              <div
                style={{
                  marginRight: "2rem",
                }}
              >
                <div onClick={() => navigate("/esg/management")}>
                  <h2
                    className={`${
                      location.pathname === "/esg/management" && "active"
                    }`}
                  >
                    ESG 경영
                  </h2>
                  <p>
                    환경, 사회, 건전한 기업 지배구조를 위한 카사요(주)의 정책 /
                    가이드 라인을 소개합니다.
                  </p>
                </div>
                <div>
                  <img src="../imgs/png/subMenuImg1.png" alt="" />
                  <span>ESG Approach</span>
                </div>
              </div>
              <div>
                <div onClick={() => navigate("/cc/management")}>
                  <h2
                    className={`${
                      location.pathname === "/cc/management" && "active"
                    }`}
                  >
                    소비자 중심 경영(CCM)
                  </h2>
                  <p>
                    카사요(주)는 소비자 중심 경영(CCM)을 지향하며, 고객의 필요와
                    요구를 최우선으로 고려하는 경영 방식을 채택하고 있습니다.
                  </p>
                </div>
                <div>
                  <img src="../imgs/png/subMenuImg2.png" alt="" />
                  <span>CCM Approach</span>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default CarsayoHeaderV2;
