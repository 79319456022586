interface CarsayoTopButtonInterface {
  goTop: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  isLast?: boolean;
}

const CarsayoTopButton = ({
  goTop,
  style,
  disabled,
  isLast,
}: CarsayoTopButtonInterface) => {
  return (
    <button
      className={`goTop ${isLast && "isBottom"}`}
      onClick={goTop}
      style={style}
      disabled={disabled}
    >
      <img src="../imgs/svg/upArrow.svg" alt="위쪽화살표" />
    </button>
  );
};

export default CarsayoTopButton;
