export interface CharacterSubDataInterface {
  id: "01" | "02" | "03";
  title: string;
  titleColor: string;
  description: string;
  bg: string;
  nextText: string;
  nextColor: string;
  nextBg: string;
  imgArray: Array<string>;
}

export const CharacterDataArray: CharacterSubDataInterface[] = [
  {
    id: "01",
    title: "카키",
    titleColor: "#303056",
    description: `26년 금융 무사고!
     대한민국 최고 세일즈 기록 보유자`,
    bg: "linear-gradient(245deg,rgba(102, 126, 234, 1) 0%,rgba(118, 75, 162, 1) 100%)",
    nextText: "카집사 보기",
    nextColor: "#fff",
    nextBg: "#6365D9",
    imgArray: [
      "/imgs/svg/character01_01.svg",
      "/imgs/svg/character01_02.svg",
      "/imgs/svg/character01_03.svg",
      "/imgs/svg/character01_04.svg",
      "/imgs/svg/character01_05.svg",
      "/imgs/svg/character01_06.svg",
      "/imgs/svg/character01_07.svg",
      "/imgs/svg/character01_08.svg",
    ],
  },
  {
    id: "02",
    title: "카집사",
    titleColor: "#FF5B79",
    description: `고객님 무엇이 궁금하신지요? 
    공감력 100%의 상담 전문가`,
    bg: "linear-gradient(180deg,rgba(46, 9, 22, 0.97) 0%,rgba(32, 10, 43, 0.69) 100%)",
    nextText: "카공이 보기",
    nextColor: "#fff",
    nextBg: "#FF5B79",
    imgArray: [
      "/imgs/svg/character02_01.svg",
      "/imgs/svg/character02_02.svg",
      "/imgs/svg/character02_03.svg",
      "/imgs/svg/character02_04.svg",
      "/imgs/svg/character02_05.svg",
      "/imgs/svg/character02_06.svg",
      "/imgs/svg/character02_07.svg",
      "/imgs/svg/character02_08.svg",
    ],
  },
  {
    id: "03",
    title: "카공이",
    titleColor: "#fff",
    description: `차량 정비부터 자동차 수리까지
안심 드라이빙을 위한 보증수표`,
    bg: "linear-gradient(180deg,rgba(91, 135, 75, 0.97) 0%,rgba(12, 55, 32, 0.87) 100%)",
    nextText: "카키 보기",
    nextColor: "#303056",
    nextBg: "#A7DE93",
    imgArray: [
      "/imgs/svg/character03_01.svg",
      "/imgs/svg/character03_02.svg",
      "/imgs/svg/character03_03.svg",
      "/imgs/svg/character03_04.svg",
      "/imgs/svg/character03_05.svg",
      "/imgs/svg/character03_06.svg",
      "/imgs/svg/character03_07.svg",
      "/imgs/svg/character03_08.svg",
    ],
  },
];
