import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Character01 = () => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section01">
      <div style={{ overflow: "hidden" }}>
        <div className="left">
          <div className="left__title">
            <VerticalMoveAnimation
              element="div"
              duration={0.3}
              direction="top"
              setAnimated={setAnimated}
            >
              <h3>카사요의 캐릭터를 소개합니다</h3>
              <span className={isAnimated ? "animation" : ""}>
                <UnderlineSketch fill="#FFE49266" />
              </span>
              <span
                className={isAnimated ? "animation star-effect" : "star-effect"}
              >
                <img src="../imgs/svg/star-effect.svg" alt="별모양" />
              </span>
            </VerticalMoveAnimation>
          </div>
          <div className="left__contents">
            <HorizontalMoveAnimation
              element="div"
              delay={0.2}
              duration={0.5}
              direction="left"
            >
              <span>
                카사요를 이끄는 경영자이자 대한민국 최고의 딜러{" "}
                <strong>카키</strong>,
              </span>
              <span>
                고객을 세심하게 케어하는 <strong>카집사</strong>, 꼼꼼한 1급
                자동차 정비사 <strong>카공이</strong>.
              </span>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="left"
            >
              <span>
                카사요를 대한민국을 넘어 세계 최고의 카 쇼핑 플랫폼으로
              </span>
              <span>성장시키기 위한 그들의 노력은 오늘도 계속된다.</span>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="div"
              delay={0.3}
              duration={0.5}
              direction="left"
            >
              <div className="character-clip-box vertical">
                <img
                  src="../imgs/png/character-clip01.png"
                  alt="캐릭터 클립 이미지1"
                />
                <span className="clip-bg">
                  <img src="../imgs/png/clip.png" alt="클립이미지" />
                </span>
              </div>
            </HorizontalMoveAnimation>
          </div>
        </div>
        <div
          className="right"
          // style={{
          //   backgroundImage: 'url("../imgs/svg/character-section01-bg.svg")',
          // }}
        >
          <img src="../imgs/svg/character-section01-bg.svg" alt="" />
          <div className="right__symbol">
            <img
              src="../imgs/svg/character-section01-symbol.svg"
              alt="카사요 심볼로고"
            />
          </div>
          <div className="right__character">
            <img
              src="../imgs/svg/character-section01.svg"
              alt="카사요 히어로즈"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character01;
