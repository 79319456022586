import React from "react";
import { Link } from "react-router-dom";

const CarsayoFooter = () => {
  return (
    <div className="section footer fp-auto-height">
      <div className="left">
        <button>
          <h2>
            <img src="../imgs/svg/logo_footer.svg" alt="카사요 로고" />
          </h2>
        </button>
        <ul>
          <li>대표이사 : 최용민</li>
          <li>(04790) 서울특별시 성동구 아차산로 49, 1403호</li>
          <li>벤처기업 20240514030044 (혁신성장형)</li>
          <li>
            사업자등록번호 : 142-87-01847 / 통신판매업신고 : 제
            2021-서울성동-00533
          </li>
          <li>광고 • 문의 메일 : info@carsayo.net</li>
          <li>Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.</li>
        </ul>
      </div>
      <div className="right">
        <a href="tel:16681571">1668-1571</a>
        <p>
          평일 오전 10시 ~ 오후 6시 (점심시간 : 13:00 ~ 14:00 / 주말 및 공휴일
          휴무)
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://play.google.com/store/apps/details?id=com.ksapp.carsayo`}
            >
              <img src="../imgs/svg/googleIcon.svg" alt="플레이스토어아이콘" />
              Play Store
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990`}
            >
              <img src="../imgs/svg/appleIcon.svg" alt="애플스토어아이콘" />
              App Store
            </a>
          </li>
        </ul>
        <div className="authentication">
          <Link
            to="https://www.kca.go.kr/ccm/certSystemOutlineView.do"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="../imgs/png/authentication_ccm.png"
              alt="소비자중심 인증마크"
            />
          </Link>
          <img
            src="../imgs/png/authentication_family.png"
            alt="가족친화우수기업 인증마크"
          />
        </div>
      </div>
    </div>
  );
};
export default CarsayoFooter;
