import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

type PopupOpen = {
  이건실화냐: boolean;
  코딜몰입점사전예약: boolean;
};
type TodayClosePopupInfo = {
  이건실화냐: number | null;
  코딜몰입점사전예약: number | null;
};

export default function MainCarsayoGiftPopup() {
  const [popupOpen, setPopupOpen] = useState<PopupOpen>({
    이건실화냐: false,
    코딜몰입점사전예약: false,
  });

  useEffect(() => {
    const popupOpenData: PopupOpen = {
      이건실화냐: true,
      코딜몰입점사전예약: true,
    };

    const popupLocalStorage = localStorage.getItem("todayClosePopupInfo");

    if (!popupLocalStorage) {
      setPopupOpen(popupOpenData);
      return;
    }

    try {
      const data = JSON.parse(popupLocalStorage) as TodayClosePopupInfo;

      if (Date.now() < Number(data.이건실화냐)) {
        popupOpenData.이건실화냐 = false;
      }

      if (Date.now() < Number(data.코딜몰입점사전예약)) {
        popupOpenData.코딜몰입점사전예약 = false;
      }
    } catch (error) {}

    setPopupOpen(popupOpenData);
  }, []);

  const setTodayPopupHidden = (target: keyof typeof popupOpen) => {
    setPopupOpen({
      ...popupOpen,
      [target]: false,
    });

    const popupLocalStorage = localStorage.getItem("todayClosePopupInfo");

    const today = new Date();
    const todayNumber = today.setTime(
      today.getTime() + 1 * 24 * 60 * 60 * 1000
    );

    try {
      const data = (
        popupLocalStorage
          ? JSON.parse(popupLocalStorage)
          : { 이건실화냐: null, 코딜몰입점사전예약: null }
      ) as TodayClosePopupInfo;
      data[target] = todayNumber;
      localStorage.setItem("todayClosePopupInfo", JSON.stringify(data));
    } catch (error) {}
  };

  const isAnyPopupOpen = useMemo(() => {
    const targetKey = Object.keys(popupOpen) as Array<keyof typeof popupOpen>;
    for (let i = 0; i < targetKey.length; i++) {
      if (popupOpen[targetKey[i]] === true) return true;
    }
    return false;
  }, [popupOpen]);

  useEffect(() => {
    if (isAnyPopupOpen) {
      setTimeout(() => {
        document.body.removeAttribute("data-scroll-locked");
        console.log(
          "data-scroll-locked removed after timeout:",
          !document.body.hasAttribute("data-scroll-locked")
        );
      }, 100);
    }
  }, [isAnyPopupOpen]);

  if (!isAnyPopupOpen) return <></>;

  return (
    <div
      className="carsayoGift-popup"
      style={
        {
          // position: "fixed",
          // left: "0",
          // right: "0",
          // bottom: "0",
          // top: "0",
          // margin: "auto",
          // zIndex: "9999",
          // maxWidth:
          //   popupOpen.이건실화냐 && popupOpen.코딜몰입점사전예약
          //     ? "1000px"
          //     : "500px",
        }
      }
    >
      {popupOpen.이건실화냐 && (
        <Link
          to={"https://kodeal.fks.co.kr"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="top-div" style={{ position: "relative" }}>
            <div
              style={{ position: "absolute", top: 0 }}
              onClick={(e) => {
                e.preventDefault();
                setPopupOpen({
                  ...popupOpen,
                  이건실화냐: false,
                });
              }}
            >
              <img src="/imgs/png/close.png" alt="닫기이미지" />
            </div>
            <div style={{ padding: 0, height: "100%" }}>
              <img
                src="/imgs/png/carsayoPopupImg01_noBg.png"
                alt="광고이미지"
              />
            </div>
          </div>
          <div className="bottom-div ">
            <button
              onClick={(e) => {
                e.preventDefault();
                setTodayPopupHidden("이건실화냐");
              }}
            >
              하루 동안 보지 않기
            </button>
            <span></span>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPopupOpen({
                  ...popupOpen,
                  이건실화냐: false,
                });
              }}
            >
              닫기
            </button>
          </div>
        </Link>
      )}
      {popupOpen.코딜몰입점사전예약 && (
        <Link
          to={"https://kodeal.fks.co.kr/teaser/license"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="top-div" style={{ position: "relative" }}>
            <div
              style={{ position: "absolute", top: 0 }}
              onClick={(e) => {
                e.preventDefault();
                setPopupOpen({
                  ...popupOpen,
                  코딜몰입점사전예약: false,
                });
              }}
            >
              <img src="/imgs/png/close.png" alt="닫기이미지" />
            </div>
            <div style={{ padding: 0, height: "100%" }}>
              <img
                src="/imgs/png/carsayoPopupImg02_noBg.png"
                alt="광고이미지"
              />
            </div>
          </div>
          <div className="bottom-div ">
            <button
              onClick={(e) => {
                e.preventDefault();
                setTodayPopupHidden("코딜몰입점사전예약");
              }}
            >
              하루 동안 보지 않기
            </button>
            <span></span>
            <button
              onClick={(e) => {
                e.preventDefault();
                setPopupOpen({
                  ...popupOpen,
                  코딜몰입점사전예약: false,
                });
              }}
            >
              닫기
            </button>
          </div>
        </Link>
      )}
    </div>
  );
}
