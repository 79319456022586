import { Link } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Pagination } from "swiper/modules";
import { useState } from "react";

export default function MCarsayoGiftPopup({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);
  const setTodayPopupHidden = () => {
    const today = new Date();
    const obj = today.setTime(today.getTime() + 1 * 24 * 60 * 60 * 1000);
    localStorage.setItem("mPopupTodayHidden", obj.toString());
    setIsOpen(false);
  };

  return isOpen ? (
    <div
      className="m-carsayoGift-popup-wrap"
      style={{
        position: "fixed",
        left: "0",
        right: "0",
        bottom: "0",
        top: "0",
        margin: "auto",
        zIndex: "9999",
      }}
    >
      <div className="m-carsayoGift-popup">
        <Link
          to={"https://kodeal.fks.co.kr/m"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="top-div">
            <Swiper
              modules={[Pagination]}
              slidesPerView={1}
              slidesPerGroup={1}
              loop={true}
              onRealIndexChange={(swiper: SwiperClass) =>
                setPage(swiper.activeIndex + 1)
              }
              speed={1000}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              spaceBetween={"30px"}
            >
              <SwiperSlide>
                <div>
                  <span>가족, 친구, 지인 모두 환영!</span>
                  <p>
                    우리 모두가 주인공이 될 수 있는
                    <br />
                    릴레이 캐시백 페스티벌
                    <img
                      src="/imgs/png/carsayoPopupIcon.png"
                      alt="축하포아이콘"
                    />
                  </p>
                  <img src="/imgs/png/mCarsayoPopupImg.png" alt="광고이미지" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="fullImg">
                  <img
                    src="/imgs/png/m_CarsayoPopupImg02.png"
                    alt="광고이미지"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="pagination">{page}/2</div>
          </div>
          <div className="bottom-div">
            <button
              onClick={(e) => {
                e.preventDefault();
                setTodayPopupHidden();
              }}
            >
              하루 동안 보지 않기
            </button>
            <span></span>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(false);
              }}
            >
              닫기
            </button>
          </div>
        </Link>
      </div>
    </div>
  ) : null;
}
