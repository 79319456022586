import { useEffect, useLayoutEffect, useState } from "react";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";
import OpacityAnimation from "../../../utils/opacityAnimation";
import DoubleQuote from "../../components/svg/DoubleQuote";
import CharacterMobileSubPage from "./CharacterMobileSub";
import { useNavigate } from "react-router-dom";

interface SubDataInterface {
  titleColor: string;
  color: string;
  subTitle: string;
  imgArray: Array<string>;
  locationText: string;
  locationData: SubDataInterface;
}

const CarsayoMobileCharacter = () => {
  const navigate = useNavigate();
  // const [isSubOpen, setSubOpen] = useState(false);
  // const [keyId, setKeyId] = useState("");
  // sectioin01 - Car Heroes
  const Section01 = () => {
    const [isAnimated, setAnimated] = useState(false);
    return (
      <section className="m-character01">
        <div>
          <VerticalMoveAnimation
            element="div"
            duration={0.3}
            direction="top"
            setAnimated={setAnimated}
          >
            <h3>
              <span>카사요의 캐릭터를</span>
              <span>소개합니다</span>
            </h3>
            <span className={isAnimated ? "animation" : ""}>
              <UnderlineSketch fill="#FFE49266" width="214" height="22" />
            </span>
            <span
              className={isAnimated ? "animation star-effect" : "star-effect"}
            >
              <img src="../imgs/svg/star-effect.svg" alt="별모양" />
            </span>
          </VerticalMoveAnimation>
        </div>
        <div>
          <HorizontalMoveAnimation
            element="p"
            delay={0.1}
            duration={0.5}
            direction="left"
          >
            <span>카사요를 이끄는 경영자이자 대한민국 최고의</span>
            <span>
              딜러 <strong>카키</strong>, 고객을 세심하게 케어하는{" "}
              <strong>카집사</strong>,
            </span>
            <span>
              꼼꼼한 1급 자동차 정비사 <strong>카공이</strong>.
            </span>
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="p"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            <span>카사요를 대한민국을 넘어 세계 최고의</span>
            <span>카 쇼핑 플랫폼으로 성장시키기 위한 그들의</span>
            <span>노력은 오늘도 계속된다.</span>
          </HorizontalMoveAnimation>
        </div>
        <div>
          <img
            src="../imgs/svg/m-section01-character01.svg"
            alt="카집사 이미지"
          />
          <img
            src="../imgs/svg/m-section01-character02.svg"
            alt="카공이 이미지"
          />
          <img
            src="../imgs/svg/m-section01-character03.svg"
            alt="카키 이미지"
          />
        </div>
      </section>
    );
  };
  // section02 - 카키
  const Section02 = () => {
    const [isAnimated, setAnimated] = useState(false);
    return (
      <section className="m-character02">
        <VerticalMoveAnimation
          element="div"
          duration={0.3}
          direction="top"
          className="m-character-subTitle"
          setAnimated={setAnimated}
        >
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </VerticalMoveAnimation>
        <div className="m-character02__contents">
          <VerticalMoveAnimation
            element="div"
            direction="top"
            className="m-character02__contents__top"
            delay={0.3}
            duration={0.7}
          >
            <div className="mini-title">#1 HEROES 2025</div>
            <p>카키</p>
          </VerticalMoveAnimation>

          <OpacityAnimation
            element="div"
            className="m-character02__contents__middle"
            delay={0.3}
            duration={1.5}
          >
            <img
              src="../imgs/svg/character-section02-img.svg"
              alt="카키 이미지"
            />
          </OpacityAnimation>
          <div className="m-character02__contents__bottom">
            <span className="double-quotes">
              <DoubleQuote />
            </span>
            <HorizontalMoveAnimation
              element="p"
              delay={0.2}
              duration={0.6}
              direction="left"
            >
              <span>
                차를 사는 사람의 마음을 가장 잘 이해하는 딜러이자 경영자!
              </span>
              <span>
                고객이 원하는 최적의 조건을 신속하고 정확하게 제시하며
              </span>
              <span>소비자중심경영, 품질경영, 환경경영 등 고객에게 </span>
              <span>신뢰를 안겨주기 위한 철저한 준비로 늘 분주하다.</span>
            </HorizontalMoveAnimation>
          </div>
        </div>
        <Expression
          className="mb"
          textColor="#fff"
          clickEvent={() => {
            navigate("detail/01");
          }}
        />
      </section>
    );
  };
  // section03 - 카집사
  const Section03 = () => {
    const [isAnimated, setAnimated] = useState(false);
    return (
      <section className="m-character03">
        <VerticalMoveAnimation
          element="div"
          duration={0.3}
          direction="top"
          className="m-character-subTitle"
          setAnimated={setAnimated}
        >
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </VerticalMoveAnimation>
        <div className="m-character03__contents">
          <VerticalMoveAnimation
            element="div"
            direction="top"
            className="m-character03__contents__top"
            delay={0.3}
            duration={0.7}
          >
            <div className="mini-title">#2 HEROES 2025</div>
            <p>카집사</p>
          </VerticalMoveAnimation>

          <OpacityAnimation
            element="div"
            className="m-character03__contents__middle"
            delay={0.3}
            duration={1.5}
          >
            <img
              src="../imgs/svg/character-section03-img.svg"
              alt="카집사 이미지"
            />
          </OpacityAnimation>
          <div className="m-character03__contents__bottom">
            <span className="double-quotes">
              <DoubleQuote fill={"#FF5B79"} />
            </span>
            <HorizontalMoveAnimation
              element="p"
              delay={0.2}
              duration={0.6}
              direction="left"
            >
              <span>고객님의 표정과 음성만 듣고도 </span>
              <span>무엇을 원하는지 캐치하는 눈치 빠른 남자!</span>
              <span>고객의 질문에 친절히 답하고 그들이 힘들어할 때</span>
              <span>곁에서 공감해 주는 카사요의 진정한 고객 지킴이!</span>
              <span>고객을 최우선으로 생각하는 마인드만큼은 세계 최고!</span>
            </HorizontalMoveAnimation>
          </div>
        </div>
        <Expression
          className="mb"
          textColor="#fff"
          fill="#ff5b79"
          clickEvent={() => {
            navigate("detail/02");
          }}
        />
      </section>
    );
  };
  // section04 - 카공이
  const Section04 = () => {
    const [isAnimated, setAnimated] = useState(false);
    return (
      <section className="m-character04">
        <VerticalMoveAnimation
          element="div"
          duration={0.3}
          direction="top"
          className="m-character-subTitle"
          setAnimated={setAnimated}
        >
          <h4>CHARACTER CONCEPT</h4>
          <span>캐릭터 컨셉</span>
        </VerticalMoveAnimation>
        <div className="m-character04__contents">
          <VerticalMoveAnimation
            element="div"
            direction="top"
            className="m-character04__contents__top"
            delay={0.3}
            duration={0.7}
          >
            <div className="mini-title">#3 HEROES 2025</div>
            <p>카공이</p>
          </VerticalMoveAnimation>

          <OpacityAnimation
            element="div"
            className="m-character04__contents__middle"
            delay={0.3}
            duration={1.5}
          >
            <img
              src="../imgs/svg/character-section04-img.svg"
              alt="카공이 이미지"
            />
          </OpacityAnimation>
          <div className="m-character04__contents__bottom">
            <span className="double-quotes">
              <DoubleQuote fill={"#A7DE93"} />
            </span>
            <HorizontalMoveAnimation
              element="p"
              delay={0.2}
              duration={0.6}
              direction="left"
            >
              <span>눈 뜨면 잠들 때까지 차와 함께하는 차 박사!</span>
              <span>자동차 수리 견적만큼은 고객님이 손해 보지 않도록,</span>
              <span>같은 불편이 두 번 다시 생기지 않도록</span>
              <span>꼼꼼히 정비하는 카사요의 1급 자동차 정비사!</span>
            </HorizontalMoveAnimation>
          </div>
        </div>
        <Expression
          className="mb"
          textColor="#303056"
          fill="#A7DE93"
          clickEvent={() => {
            navigate("detail/03");
          }}
        />
      </section>
    );
  };
  //   section05 - Character Application
  const Section05 = () => {
    const [isAnimated, setAnimated] = useState(false);
    return (
      <section className="m-character05">
        <VerticalMoveAnimation
          element="div"
          duration={0.3}
          direction="top"
          className="m-character-subTitle"
          setAnimated={setAnimated}
        >
          <h4>CHARACTER APPLICATION</h4>
          <span>캐릭터 모음</span>
        </VerticalMoveAnimation>
        <ul>
          <HorizontalMoveAnimation
            element="li"
            delay={0.1}
            duration={0.5}
            direction="left"
          >
            <img
              src="../imgs/svg/character_section05_img01.svg"
              alt="카사요히어로즈이미지1"
            />
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="right"
          >
            <img
              src="../imgs/svg/character_section05_img02.svg"
              alt="카사요히어로즈이미지2"
            />
          </HorizontalMoveAnimation>
          <HorizontalMoveAnimation
            element="li"
            delay={0.2}
            duration={0.5}
            direction="left"
          >
            <img
              src="../imgs/svg/character_section05_img03.svg"
              alt="카사요히어로즈이미지3"
            />
          </HorizontalMoveAnimation>
        </ul>
      </section>
    );
  };

  useLayoutEffect(() => {
    const goBackHome = () => {
      navigate("/");
    };
    window.history.pushState(null, "", window.location.pathname);
    window.addEventListener("popstate", goBackHome);
    return () => window.removeEventListener("popstate", goBackHome);
  }, [navigate]);

  return (
    <div className="m-content">
      <Section01 />
      <Section02 />
      <Section03 />
      <Section04 />
      <Section05 />
      {/* <CharacterMobileSubPage
        isShown={isSubOpen}
        setClose={() => {
          setSubOpen(false);
        }}
        keyId={keyId}
      /> */}
    </div>
  );
};
export default CarsayoMobileCharacter;
