import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";

const Overview04 = () => {
  return (
    <div className="section overview__section04">
      <div
        className="overview__section04__content"
        style={{ justifyContent: "center", gap: "10vh" }}
      >
        <VerticalMoveAnimation
          element="div"
          duration={0.5}
          direction="top"
          className="title"
        >
          2024
        </VerticalMoveAnimation>
        <div className="box">
          <ul className="history">
            <HorizontalMoveAnimation
              element="li"
              delay={0.2}
              direction="left"
              duration={0.5}
            >
              <ul className="history__detail">
                <li className="content noImg">
                  <span>2024.11</span>
                  <p>
                    <span>ISO 37301(준법경영시스템)</span>
                    <span className="small">인증 획득</span>
                  </p>
                </li>
                <li className="content noImg">
                  <span>2024.11</span>
                  <p>
                    <span>ISO 37001(부패방지경영시스템)</span>
                    <span className="small">인증 획득</span>
                  </p>
                </li>
              </ul>
            </HorizontalMoveAnimation>
            <HorizontalMoveAnimation
              element="li"
              delay={0.4}
              duration={0.5}
              direction="left"
            >
              <ul className="history__detail">
                <li
                  className="content rotate noImg"
                  style={{ borderRadius: "2.5rem" }}
                >
                  <span>2024.06</span>
                  <p>공식 카사요 앱 출시</p>
                  <img src="../imgs/png/mockup.png" alt="앱 목업 이미지" />
                </li>
              </ul>
            </HorizontalMoveAnimation>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Overview04;
