import { motion } from "framer-motion";
import VerticalMoveAnimation from "../../../utils/verticalMoveAnimation";
import { useState } from "react";
import TypingTextAnimation from "../../../utils/typingTextAnimation";
import UnderlineSketch from "../../components/svg/UnderlineSketch";
import HorizontalMoveAnimation from "../../../utils/horizontalMoveAnimation";
import Expression from "../../components/svg/Expression";
import CarsayoCharacterPopup from "../../components/CarsayoCharacterPopup";

interface CharacterInterface {
  keyId: string;
  isPopupOpen: boolean;
  setPopupOpen: () => void;
}

const Character04 = ({
  keyId,
  isPopupOpen,
  setPopupOpen,
}: CharacterInterface) => {
  const [isAnimated, setAnimated] = useState(false);
  return (
    <div className="section character__section04">
      <div>
        <div
          className="company__section__title character"
          style={{
            backgroundImage: "url(../imgs/svg/character-subtitle-symbol.svg)",
          }}
        >
          <VerticalMoveAnimation element="h3" duration={0.5} direction="top">
            CHARACTER CONCEPT
            <span>캐릭터 컨셉</span>
          </VerticalMoveAnimation>
        </div>
        <div className="character__contents">
          <div className="left">
            <VerticalMoveAnimation
              element="div"
              className="left__title"
              duration={0.5}
              direction="top"
            >
              <p>#3 HEROES 2025</p>
              <h3>
                <span>카공이 </span>
              </h3>
            </VerticalMoveAnimation>
            <div className="left__contents">
              <HorizontalMoveAnimation
                element="div"
                delay={0.2}
                duration={0.5}
                direction="left"
              >
                <span>눈 뜨면 잠들 때까지 차와 함께하는 차 박사!</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.25}
                duration={0.5}
                direction="left"
              >
                <span>자동차 수리 견적만큼은 고객님이 손해 보지 않도록,</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.3}
                duration={0.5}
                direction="left"
              >
                <span>같은 불편이 두 번 다시 생기지 않도록</span>
              </HorizontalMoveAnimation>
              <HorizontalMoveAnimation
                element="div"
                delay={0.35}
                duration={0.5}
                direction="left"
              >
                <span>꼼꼼히 정비하는 카사요의 1급 자동차 정비사!</span>
              </HorizontalMoveAnimation>
            </div>
            <Expression
              textColor="#303056"
              fill="#A7DE93"
              clickEvent={setPopupOpen}
            />
            <div className="character-clip-box">
              <img
                src="../imgs/png/character-clip04.png"
                alt="캐릭터 클립 이미지4"
              />
              <span className="clip-bg">
                <img src="../imgs/png/clip.png" alt="클립이미지" />
              </span>
            </div>
          </div>
          <div className="right">
            <img
              src="../imgs/svg/character-section04-img.svg"
              alt="카공이  이미지"
            />
            <div className="character-nameTag">
              <div>
                <p>
                  차량 정비부터 자동차 수리까지
                  <br />
                  안심 드라이빙을 위한 보증수표
                </p>
                <h5>카공이</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Character04;
